












































































































































































































































































































import { paymentStatuses } from "@/shared/constants";
import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import { Customer, Raid, SheetShiftString, Faction } from "@/interfaces/Raids";
import RaidBookingMenu from "@/components/booking/RaidBookingMenu.vue";
import { mailedOptions } from "@/shared/constants";
import moment from "moment-timezone";

export default Vue.extend({
  name: "StringHistoryForm",
  components: { RaidBookingMenu },
  data: () => ({
    valid: false,
    paymentStatuses: Object.values(paymentStatuses),
    paymentStatus: paymentStatuses.all,
    runs: [],
    sheetStrings: [],
    mainRealm: null,
    paymentFaction: null,
    mailed: mailedOptions[1],
    mailedIds: [] as Array<string>,
    loading: false,
    snackbar: false,
    snackbarText: "Should not show",
    snackbarColor: "red",
    timeout: 2000,
    textRules: [
      (v: string) => !!v || "Field is required",
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
    ],
    required: [(v: any) => !!v || "Field is required"],
  }),
  methods: {
    moment(date: string) {
      return moment(date).tz("Europe/Budapest");
    },
    copy(val: string) {
      navigator.clipboard.writeText(val);
      this.snackbarToggle({
        message: "Copied successfully!",
        color: "green",
      });
    },
    goBack(e) {
      e.preventDefault();
      this.$router.go(-1);
    },
    copyStrings(bookingString) {
      if (bookingString && typeof bookingString === "string") {
        navigator.clipboard.writeText(bookingString);
      } else {
        const bookings = this.history;

        const strings: Array<string> = [];
        bookings.forEach((booking: Customer) => {
          strings.push(booking.string || "");
        });
        navigator.clipboard.writeText(strings.join("\n"));
      }
    },
    async loadRuns() {
      this.runs = this.$store.state.raids;
      this.sheetStrings = this.$store.state.sheetStrings;
    },
    snackbarToggle(snackbarData: SnackbarData) {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
    setMailed(booking: Customer) {
      const customerData = {
        ...booking,
        mailed: !booking.mailed,
      };
      const state = this.mailed;
      this.mailed =
        this.mailed.value === mailedOptions[0].value
          ? mailedOptions[1]
          : mailedOptions[0];
      booking.mailed = !booking.mailed;
      if (booking.section.toLowerCase() === "shift") {
        this.$store.dispatch("updateShiftString", {
          booking: customerData,
        });
      } else if (booking.section.toLowerCase() === "mount") {
        this.$store.dispatch("updateMountBooking", {
          booking: customerData,
          raid: booking.raid,
        });
      } else {
        this.$store.dispatch("updateBooking", {
          booking: customerData,
          raid: booking.raid,
        });
      }
      Vue.nextTick(() => {
        this.mailed = state;
      });
    },
    formatDate: function (date: string) {
      return this.moment(date).format("MM-DD");
    },
    downloadZipFile() {
      const fileUrl = "https://oblivion-marketplace.com/OblivionAdTools.zip";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "OblivionAdTools.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  computed: {
    userRoles: function (): Array<string> {
      return this.$store.state.roles;
    },
    mailedOptions() {
      return mailedOptions;
    },
    unfilteredBookings: function () {
      const bookings: Array<Customer> = [];
      this.runs.forEach((raid: Raid) => {
        const allBooking: Array<Customer> = [
          ...raid.Buyer,
          ...(raid.Backup || []),
        ];
        allBooking.map((booking: Customer) => {
          booking.raid = raid.id;
          booking.raidDate = raid.date as string;
        });
        bookings.push(...allBooking);
      });

      this.sheetStrings.forEach((string: SheetShiftString) => {
        const booking: Customer = {
          id: string.string.split(":")[2],
          section: "Shift",
          faction: "Horde",
          typeOfBoost: "ShiftBuyer",
          armorType: "Mail",
          buyer: string.buyerInfo,
          buyerClass: "Paladin",
          paymentRealm: string.realm,
          paymentFaction: string.faction as Faction,
          price: 1000,
          collectedGold: Number(string.amountToMail),
          advCut: "TAKEN",
          note: "",
          btag: "",
          paid: true,
          string: string.string,
          goldWith: string.gc,
          completed: true,
          fullyPaidAtCreate: true,
          mailed: string.mailed,
          customerStatus: "In group",
          raid: { date: string.date },
          remainingPayment: 0,
          owesForString: Number(string.amountToMail),
          advertiser: { username: "placeholder", id: "4711" },
        };
        bookings.push(booking);
      });
      return bookings;
    },
    history: function () {
      const bookings: Array<Customer> = [];
      bookings.push(
        ...this.unfilteredBookings.filter((booking: Customer) => {
          let need = true;
          if (this.mainRealm !== null && need) {
            need =
              booking.string?.split(":")[0].split("-")[1] === this.mainRealm;
          }
          if (this.paymentFaction !== null && need) {
            need =
              booking.paymentFaction.toLowerCase() ===
              (this.paymentFaction || "").toLowerCase();
          }
          if (this.mailed !== null && need) {
            if ((this.mailed as any).value) {
              if (booking.mailed) {
                need = true;
              } else {
                need = false;
              }
            } else {
              if (!booking.mailed) {
                need = true;
              } else {
                need = false;
              }
            }
          }
          return (
            booking.paid &&
            booking.customerStatus?.toLowerCase() === "in group" &&
            need &&
            this.$store.state.user.id === booking.goldWith &&
            booking.paymentRealm.toUpperCase() !== "BALANCE"
          );
        })
      );
      return bookings.sort((a, b): number => {
        return a.paymentRealm.localeCompare(b.paymentRealm);
      });
    },
    unmailedAmount: function () {
      const bookings: Array<Customer> = this.history;
      return bookings.filter((booking: Customer) => {
        return !booking.mailed;
      }).length;
    },
    unmailedAmountGold: function () {
      let sum = 0;
      const bookings: Array<Customer> = this.history;
      bookings.forEach((booking: Customer) => {
        if (booking.owesForString) {
          sum += booking.owesForString;
        }
      });
      return sum;
    },
    mainRealmOptions: function () {
      const bookings: Array<Customer> = this.history;
      const mainRealms = bookings.map((booking: Customer) => {
        return booking.string?.split(":")[0].split("-")[1];
      });
      return mainRealms.sort();
    },
    paymentFactionOptions: function () {
      const bookings: Array<Customer> = this.history;
      return bookings.map((booking: Customer) => {
        return booking.paymentFaction;
      });
    },
  },
  mounted: function () {
    this.$store.dispatch("getRaids", {
      removeFilter: ["completed"],
      start: moment()
        .tz("Europe/Berlin")
        .subtract(7, "day")
        .format("YYYY-MM-DD"),
    });
    this.$store.dispatch("getMountRaids", {
      removeFilter: ["completed"],
      start: moment()
        .tz("Europe/Berlin")
        .subtract(7, "day")
        .format("YYYY-MM-DD"),
    });
    this.$store.dispatch("getSheetStrings");

    this.mailedIds = JSON.parse(localStorage.getItem("mailed") || "[]");
  },
  watch: {
    "$store.state.raids": {
      deep: true,
      handler: function () {
        this.loadRuns();
      },
    },
    "$store.state.sheetStrings": {
      deep: true,
      handler: function () {
        this.loadRuns();
      },
    },
  },
});
