var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.isAuthenticated)?_c('div',{staticClass:"fill-height",staticStyle:{"background":"linear-gradient(#000923, #181819)","padding":"90px 30px 30px"}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"pb-md-6",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-6 pr-6",attrs:{"cols":"12","sm":"2","md":"4","align-self":"center"}},[_c('v-divider',{staticClass:"oblivion-divider"})],1),_c('v-col',{staticClass:"oblivion-divider-text text-h3",attrs:{"cols":"12","sm":"8","md":"4","align-self":"center","align":"center"}},[_vm._v(" Select Your Matter ")]),_c('v-col',{staticClass:"pl-6 pr-6",attrs:{"cols":"12","sm":"2","md":"4","align-self":"center"}},[_c('v-divider',{staticClass:"oblivion-divider"})],1)],1),_c('v-row',{staticStyle:{"padding-bottom":"30px"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/booking/raid/overview"}},[_c('raid-overview-card',{staticClass:"booking-card"})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/booking/raid/bookandhistory"}},[_c('booking-and-history-card',{staticClass:"booking-card"})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/booking/raid/shiftbookandhistory"}},[_c('shift-booking-and-history-card',{staticClass:"booking-card"})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/booking/raid/stringhistory"}},[_c('string-history-card',{staticClass:"booking-card"})],1)],1),(
          _vm.$store.state.roles.some(
            function (role) { return role === 'GOLDCOLLECTOR' ||
              role === 'RAIDLEADER' ||
              role === 'PREMIUMRAIDLEADER'; }
          )
        )?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/booking/raid/overview/leaderandgc"}},[_c('your-runs-card',{staticClass:"booking-card"})],1)],1):_vm._e(),(
          _vm.$store.state.roles.some(
            function (role) { return role === 'RAIDMANAGER' || role === 'ADMIN'; }
          )
        )?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/booking/raid/overview/manager"}},[_c('manager-card',{staticClass:"booking-card"})],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/booking/raid/cyclehistory"}},[_c('cycle-history-card',{staticClass:"booking-card"})],1)],1),(
          _vm.$store.state.roles.some(
            function (role) { return role === 'ADMIN' ||
              role === 'RAIDLEADER' ||
              role === 'GOLDCOLLECTOR' ||
              role === 'PREMIUMRAIDLEADER'; }
          )
        )?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/booking/raid/runhistory"}},[_c('your-run-history-overview-card',{staticClass:"booking-card"})],1)],1):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }